<template lang="">
    <div>
      <h6 class="heading-small text-muted mb-4">ข้อมูลสัญญาซัพพลายเออร์</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-4">
                      <label class="form-label">หมายเลขสัญญา</label>
                      <base-input name="contract_id" placeholder="หมายเลขสัญญา" v-model="item.contract_id" :rules="{required: true}"></base-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-4">
                      <label class="form-label">ซัพพลายเออร์</label>
                      <Select2 v-model="item.suppleir_id" :options="suppliers" placeholder="ซัพพลายเออร์" :rules="{required: true}"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-4">
                      <label class="form-label">ซัพพลายเออร์ในสัญญา</label>
                      <base-input name="contract_name" placeholder="ซัพพลายเออร์ในสัญญา" v-model="item.contract_name" :rules="{required: true}"></base-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-4">
                      <label class="form-label">สถานะ</label>
                      <Select2 v-model="item.status" :options="status" :settings="{allowClear: true}" placeholder="สถานะสัญญา"/>
                      <base-input name="status" v-model="item.status" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">ระยะเวลา</label>
                  <base-input name="contract_duration" placeholder="ระยะเวลา" v-model="item.contract_duration" v-number-only :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">วันที่เริ่มต้น</label>
                  <date-picker :format="'DD/MM/YYYY'" v-model="item.start" class="w-100" input-class="form-control" placeholder="วันที่เริ่มต้น" :disabled-date="disableStartDate" :append-to-body="false" :popup-style="{left: 0}"></date-picker>
                  <base-input name="start" v-model="start" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">วันที่สิ้นสุด</label>
                  <date-picker :format="'DD/MM/YYYY'" v-model="item.end" class="w-100" input-class="form-control" placeholder="วันที่สิ้นสุด" :disabled-date="disabledEndDate" :append-to-body="false" :popup-style="{left: 0}"></date-picker>
                  <base-input name="end" v-model="end" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">เงื่อนไขการสั่งซื้อต่อเดือน</label>
                  <base-input name="cond_monthly_minimum_order" placeholder="เงื่อนไขการสั่งซื้อต่อเดือน" v-model="item.cond_monthly_minimum_order" v-number-only :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">กำลังการผลิตต่อวัน</label>
                  <base-input name="cond_daily_minimum_capacity" placeholder="กำลังการผลิตต่อวัน" v-model="item.cond_daily_minimum_capacity" v-number-only :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">สถานะสัญญา</label>
                  <Select2 v-model="item.contract_status" :options="contract_status" :settings="{allowClear: true}" placeholder="สถานะสัญญา"/>
                  <base-input name="contract_status" v-model="item.contract_status" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-8">
                <div class="mb-4">
                  <label class="form-label">หมายเหตุ</label>
                  <b-form-textarea  id="textarea"
                                    v-model="item.remark"
                                    placeholder="หมายเหตุ"
                                    rows="3"
                                    max-rows="6"
                                  ></b-form-textarea>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">แนบไฟล์ <small>(กรุณาอัพโหลดเฉพาะไฟล์ประเภท .jpg, .png, .pdf)</small></label>
                  <b-form-file id="file-default" multiple :file-name-formatter="formatNames" v-model="files" @change="uploadFileChangeHandler" accept="image/jpeg, image/png, application/pdf"></b-form-file>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" v-if="item.fileDatas&&item.fileDatas.length>0"/>
        <div class="row" v-if="item.fileDatas&&item.fileDatas.length>0">
          <div class="col-12">
            <label class="form-label">ไฟล์</label>
            <table class="table align-middle table-bordered mb-4">
              <thead>
                <tr class="text-center" >
                  <th style="min-width: 50px; width: 1px;">#</th>
                  <th class="cursor-pointer">ชื่อไฟล์</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in item.fileDatas" :key="`file_selected_${data.id}_${index}`">
                  <td class="text-center">{{index+1}}</td>
                  <td>
                    <a :href="data.url" :download="data.original_name" target="_blank" v-if="data.id>0">{{data.original_name}}</a>
                    <span v-else>{{data.original_name}}</span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i class="fas fa-times text-danger" @click="onDeleteHandler(data, index)" title="ลบ"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="item.fileDatas.length === 0" class="text-center">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="my-4"/>
      </div>
    </div>
</template>
<script>
import Constants from '../../../util/constants';
export default {
  name: 'supplier_contact-view-form-supplier_contact',
  data () {
    return {
      start: "",
      end: "",
      contract_status: [],
      files: [],
      status: [],
      fileSelect: '',
      flagDelete: false
    }
  },
  props: [
    'item', "suppliers"
  ],
  methods: {
    disableStartDate(date) {
      if(this.item.end){
        return date > this.item.end;
      }
      return false;
    },
    disabledEndDate(date){
      if(this.item.start){
        return date < this.item.start;
      }
      return false;
    },
    uploadFileChangeHandler(e){
      const files = e.target.files;
      for(const f of files){
        const fData = {
          id: '',
          original_name: f.name,
          mime_type: f.type,
          size: f.size,
          url: ''
        };
        this.item.fileDatas.push(fData);
        this.item.files.push(f);
      }
    },
    formatNames(files) {
      return `${this.item.files.length} files selected`;//this.item.files.length === 1 ? this.item.files[0].name : `${this.item.files.length} files selected`;
    },
    onDeleteHandler(d, index){
      if(d.id!=0){
        this.item.deletes.push(d.id);
        if(d.id_t){
          this.item.deletes.push(d.id_t);
        }
      }else{
        let cnt = this.item.fileDatas.filter(v=>v.id!=0).length;
        this.item.files.splice(index-cnt, 1);
      }
      this.flagDelete = true;
      this.item.fileDatas.splice(index, 1);
    }
  },
  watch:{
    'item.start'(newVal){
      if(newVal){
        this.start = ""+newVal;
      }else{
        this.start = "";
      }
    },
    'item.end'(newVal){
      if(newVal){
        this.end = ""+newVal;
      }else{
        this.end = "";
      }
    }
  },
  mounted() {
    this.contract_status = Constants.supplier_contract_status;
    this.status = Constants.status;
  }
}
</script>
