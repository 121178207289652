<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มสัญญาซัพพลายเออร์</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
              <form-supplier-contact  :item="item" :suppliers="suppliers"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/supplier-contract'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formSupplierContact from './form.supplier_contact';
import DateUtils from '../../../util/dateUtils';
import Constants from '../../../util/constants';
import StringUtils from '../../../util/StringUtils';
export default {
  name: 'supplier_contact-view-page-supplier_contact-add',
  data () {
    return {
      permission:{
        appSlug: 'supplier-contract',
        actionData: {}
      },
      item: {
        id: '',
        suppleir_id: '',
        start: '',
        end: '',
        created: '',
        updated: '',
        cond_monthly_minimum_order: '',
        cond_daily_minimum_capacity: '',
        data: '',
        contract_id: '',
        contract_name: '',
        contract_duration: '',
        status: Constants.status[0].id,
        contract_status: Constants.supplier_contract_status[0].id,
        files: [],
        fileDatas: [],
        deletes: []
      },
      suppliers: []
    }
  },
  methods: {
    async addHandler () {
      const file = this.item.files;
      delete this.item.fileDatas;
      delete this.item.files;
      delete this.item.deletes;
      this.item.start = DateUtils.dateFormat(this.item.start, "YYYY-MM-DD");
      this.item.end = DateUtils.dateFormat(this.item.end, "YYYY-MM-DD");
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.postData("/ab_supplier_contract", this.item);
      if(result&&result.status.code=="200"){
        if(file&&file.length>0){
          const s3Auth = await this.HttpServices.authenS3();
          if(s3Auth._info){
            const medias = [];
            const accessToken = s3Auth._info.secret.accessToken;
            for(const f of file){
              let params = new FormData();
              params.append("file", f);
              params.append("title", f.name);
              params.append("file_category", 'supplier-contract');
              const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
              if(resultMedia.media&&resultMedia.media.length>0){
                const media = resultMedia.media[0];
                const mediaUpload = {
                  id: media.id,
                  original_name: media.original_name,
                  mime_type: media.mime_type,
                  size: media.size,
                  url: media.url
                };

                params = new FormData();
                const thumbFile = await StringUtils.resizeBlobToFile({file: f, maxSize: 200});
                params.append("file", thumbFile);
                params.append("title", thumbFile.name);
                params.append("file_category", 'supplier-contract');
                let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
                if(resultThumb.media&&resultThumb.media.length>0){
                  const mediaThumb = resultThumb.media[0];
                  mediaUpload.id_t = mediaThumb.id;
                  mediaUpload.original_name_t = mediaThumb.original_name;
                  mediaUpload.mime_type_t = mediaThumb.mime_type;
                  mediaUpload.size_t = mediaThumb.size;
                  mediaUpload.url_t = mediaThumb.url;
                }
                medias.push(mediaUpload);
              }
            }
            const params = {media: JSON.stringify(medias)};
            await this.HttpServices.postFormData(`/ab_supplier_contract/upload/${result.data}`, params);
          }
          // for(const v of file){
          //   const params = new FormData();
          //   params.append("fileUpload", v);
          //   await this.HttpServices.postFormData(`/ab_supplier_contract/upload/${result.data}`, params);
          // }
        }
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/supplier-contract');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler(){
      this.$router.push("/supplier-contract");
    }
  },
  components: {
    formSupplierContact
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.suppliers = await this.HttpServices.getMasterData("/master/getSuppliers");
    if(this.suppliers&&this.suppliers.length>0){
      this.item.suppleir_id = this.suppliers[0].id;
    }
  },
}
</script>
